<template>
  <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
</template>

<script>
// eslint-disable-next-line camelcase
import zh_CN from '@/lang/zh_CN'

const languages = { zh_CN }

export default {
  data() {
    return {
      locale: languages[this.$i18n.locale]
    }
  },
  computed: {
    language() {
      return this.$i18n.locale
    }
  },
  watch: {
    language() {
      this.locale = languages[this.language]
    }
  }
}
</script>
